<template>
      <v-text-field
        clearable
        label="Address"
        :rules="rules"
        hint="*Required."
        v-model="userAddress"
        persistent-hint
        autocomplete="off"
      ></v-text-field>
</template>

<script>
    export default {
        name: 'UserAddress',
        data: () => ({
            rules: [
            value => !!value || 'Required.',
            value => (value && value.length >= 3) || 'Min 2 characters',
            ],
            
        }),
        computed: {
            userAddress: {
                get() {
                    return this.$store.getters.owner.address.physical;
                },
                set(value) {
                    this.$store.commit('updateOwner', {
                        address: {
                            physical: value,
                            billing: value
                        }
                    });
                }
            }
        }
    }
</script>