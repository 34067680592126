<template> 
    <div class="section-wrap">
        <div class="form" v-show="!loading">
            <StepHeader :stepDetails="stepHeaderConfig" :stepHeading="petInfoHeaderTitle" :contactInfo="this.getPartnerContactInfo()" :imageName="step1SideImgSource" ref="stepHeader"></StepHeader>
            <div class="step-body" v-for="stepConfig in stepsConfig" v-bind:key="stepConfig.Id">
                <v-form :ref="stepConfig.Id" v-model="stepConfig.isValid" lazy-validation>
                    <v-container v-if="(stepHeaderConfig.currentStep - 1) == stepConfig.Order">
                        <v-row justify="center">
                            <v-col cols="12">
                                <v-card elevation="2" class="v-card-form"> 
                                    <v-card-text>
                                        <v-row justify="center">
                                            <v-col cols="12" md="7" class="form-col-section">
                                                <v-card-text class="step1-form-wrapper" :class="quoteFunnelURLIdentifier">
                                                    <v-row v-if="retrieveQuoteFunctActive()">
                                                        <v-col cols="12">
                                                            <v-row class="d-flex flex-row mt-12 mb-2">
                                                                <v-col cols="12" sm="6" class="d-flex flex-column flex-grow-1">
                                                                    <h3>Not your first time here?</h3>
                                                                    <h4 class="font-weight-light">We may have your previous quote waiting for you</h4>
                                                                </v-col>
                                                                <v-col cols="12" sm="6" class="d-flex justify-content-end">
                                                                    <v-btn :block="$vuetify.breakpoint.smAndDown" class="flex-grow-1" @click="goToRetrieve" outlined>Retrieve a Saved Quote</v-btn>
                                                                </v-col>
                                                                
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                   
                                                    <div class="element-container">
                                                        <div class="CustomElementIdParagraph">
                                                            <p role="heading" aria-level="2">
                                                                {{ petInfoSectionTitle }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <!-- </div> -->
                                                    <div :id="guidToName(element.Id)" class="element-container" v-for="element in stepConfig.Elements" v-bind:key="element.Id">
                                                        <pet-species ref="AddPetSpecies" class="mb-4" :validateSpecies="validateChildComponents" v-if="element.Id === elementGuid.ElementIdAnimal" @isExotic="isExotic($event)"/>                               
                                                        <pet-name class="mb-3" v-else-if="element.Id === elementGuid.ElementIdPetName && !isExoticPet" />
                                                        <pet-age class="mb-3" v-else-if="element.Id === elementGuid.ElementIdAge && !isExoticPet" />
                                                        <pet-gender ref="AddPetGendre" class="mb-4" :validateGender="validateChildComponents" v-else-if="element.Id === elementGuid.ElementIdGender && !isExoticPet" />
                                                        <pet-breed-type ref="AddPetBreed" class="mb-4" :validateBreedType="validateChildComponents" v-else-if="element.Id === elementGuid.ElementIdBreed && !isExoticPet" />
                                                        <user-email-address class="mb-3" v-else-if="element.Id === elementGuid.ElementIdEmail && !isExoticPet" /> 
                                                        <user-zip-code class="mb-3" v-else-if="element.Id === elementGuid.ElementIdZipCode && !isExoticPet" />
                                                        <user-address class="mb-3" v-else-if="element.Id === elementGuid.UserEmailAddress && !isExoticPet" />
                                                        <user-first-name class="mb-3" v-else-if="element.Id === elementGuid.ElementIdFirstName && !isExoticPet" />
                                                        <user-last-name class="mb-3" v-else-if="element.Id === elementGuid.ElementIdLastName && !isExoticPet" />
                                                        <user-phone class="mb-3" v-else-if="element.Id === elementGuid.ElementIdPhone && !isExoticPet" />
                                                        <custom-paragraph class="mb-3" :value-text="personalInfoSectionTitle" v-else-if="element.Id === elementGuid.CustomElementIdParagraph && !isExoticPet" />
                                                    </div>
                                                    <div class="element-container">
                                                        <user-employeer ref="Employeer" class="mb-3" v-if="showPartnerSearch && !isExoticPet"></user-employeer>
                                                    </div>
                                                    <template v-if="showFederalField(enrollmentCode) && !partnerId && !isExoticPet">
                                                        <div class="element-container">
                                                            <div class="CustomElementIdParagraph">
                                                                <p role="heading" aria-level="2">
                                                                    Federal Affiliation
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <v-radio-group :rules="[federalSelectionRule]" row v-model="federalTypeSelection" @change="handleFederalRadioChange">
                                                                <template v-for="(item, index) in federalList">
                                                                    <div v-if="item.label === 'Civilian'" style="width: 100%;"class="d-flex flex-grow-1 mb-3 font-weight-bold">Civilian</div>

                                                                    <div v-else-if="item.label === 'Uniformed Services'" style="width: 100%;"class="d-flex flex-grow-1 mb-3 mt-3 font-weight-bold">Uniformed Services</div>

                                                                    <v-radio v-else :key="index" :value="item.value" :label="item.label"></v-radio>
                                                                </template>
                                                            </v-radio-group>
                                                        </div>
                                                    </template>
                                                   
                                                   <v-card class="my-8" flat color="ml_lightgray" v-if="!isExoticPet">
                                                        <v-card-text class="d-flex flex-row ">
                                                            <v-icon class="mr-2" color="primary">mdi-information</v-icon>
                                                            <span class="body-1">Got another pet? You can add it on the next step.</span>
                                                        </v-card-text>
                                                   </v-card>
                                                    <!-- <v-divider class="mt-10" v-if="!isExoticPet" role="none"></v-divider>
                                                    <span class="caption" v-if="!isExoticPet">* All fields are required</span> -->
                                                   
                                                </v-card-text>
                                            </v-col>
                                            <v-col cols="12" md="5" class="side-col-section" v-show="$vuetify.breakpoint.mdAndUp" >
                                                <v-card color="ml_lightgray" outlined elevation="0"  class="side-card">
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="10" offset="1" class="text-center">
                                                                <v-card-title>
                                                                    <v-img 
                                                                        alt="Snoopy"
                                                                        class="shrink side-card-img"
                                                                        contain
                                                                        :src="step1SideImgSource"
                                                                        transition="scale-transition"
                                                                        max-height="212"
                                                                        max-width="212"
                                                                        width="auto"
                                                                        v-if="step1SideImgSource !== ''"
                                                                    />
                                                                    <p role="heading" aria-level="2">
                                                                        {{ petInfoSidebarTitle }}
                                                                    </p>
                                                                </v-card-title>
                                                                <v-card-text>
                                                                    <v-divider role="none"></v-divider>
                                                                    <ul class="my-5" role="list" v-if="dynamicContent && dynamicContent.petInfoSidebarCoverageList.length">
                                                                        <li role="listitem" v-for="item in dynamicContent.petInfoSidebarCoverageList">{{ item }}</li>
                                                                    </ul>
                                                                    <ul class="my-5" role="list" v-else>
                                                                        <li role="listitem" v-for="item in defaultDynamicContent?.petInfoSidebarCoverageList">{{ item }}</li>
                                                                    </ul>
                                                                    <v-divider role="none"></v-divider>
                                                                </v-card-text>
                                                                <v-card-actions class="d-flex flex-column black--text">
                                                                    <v-btn block x-large class="nextBtnFocus" color="ml_purple" @click="changeStep(true)" :disabled="isExoticPet">
                                                                        {{ petInfoSidebarBtnText }}
                                                                        <v-icon small>mdi-play</v-icon>
                                                                    </v-btn>

                                                                    <p class="pt-3 pb-7 support-phone">Or call: <a :href="`tel:${getPartnerContactInfo()}`" v-on:click="trackAdobeEventPhone()">{{getNumbertoAlphanumberic(getPartnerContactInfo())}}</a></p>
                                                                    <v-icon x-large>$metlifeHeart</v-icon>
                                                                    <h4 role="heading" aria-level="2" class="support-heading">Customer Support</h4>
                                                                    <p class="support-note" v-html="petInfoSidebarSupportBlockHtml"></p>
                                                                </v-card-actions>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card>    
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            
                        </v-row>
                    </v-container>
                </v-form>
            </div>
            <v-footer ref="controlZoomLevel" app color="white" elevation="4" v-if="!isExoticPet">
                <v-row class="d-flex justify-center align-center">
                    <v-col md="auto" cols="12" class="d-flex justify-center align-center">
                        <span class="body-1">
                            {{ petInfoFooterInstructions }}
                        </span>
                    </v-col>
                    <v-col cols="auto" v-if="currentStep > 0">
                        <v-btn @click="changeStep(false)">Back: {{stepFooterConfig.prevActionText}}</v-btn>
                    </v-col>
                    <v-col cols="auto" v-if="stepsConfig && currentStep < stepsConfig.length">
                        <v-btn color="ml_green" @click="changeStep(true)" ref="nextBtn">
                            <!-- Next: {{stepFooterConfig.nextActionText}} -->
                            Next Step <v-icon class="mt-n1">mdi-menu-right</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-footer>
        </div>
        <DotLoader :loading="loading" :loaderText="loaderText" :onLoadingComplete="focusHeader"/>

        <v-overlay class="cookie-overlay" :z-index="zIndex" :value="showCookieOverlay">
            <v-footer app>
                <v-row class="d-flex pa-5">
                    <v-col
                    class="text-center justify-center align-center"
                    cols="12"
                    aria-modal="true" role="dialog" id="cookieBannerForm" aria-labelledby="cookieBannerTitle">
                        <p class="d-inline-block mr-2" 
                        id="cookieBannerTitle"
                        >This website stores data such as cookies to enable important site functionality including analytics, targeting, and personalization.</p>
                        <div class="d-inline-block">
                            <v-btn class="mr-2" color="primary" @click="persistCookie()" ref="acceptCookieBtn" @keydown="handleAcceptCookieKeydown">
                                Accept
                            </v-btn>
                            <span class="d-sr-only" aria-live="polite">{{acceptSRAnnouncement}}</span>
                        </div>
                    </v-col>
                </v-row>
            </v-footer>
        </v-overlay>
    </div>
</template>

<script>
    import StepHeader from '../../components/challenger/shared/StepHeader.vue';
    import SendQuote from '../../components/shared/SendQuote.vue';
    import DotLoader from '../../components/shared/DotLoader.vue';
    import {
        PetSpecies, PetGender, PetName, PetAge, PetColor, PetBreedType, UserEmailAddress, UserZipCode, UserEmployeer,
        UserAddress, UserFirstName, UserLastName, UserPhone, CustomParagraph, WorkEmailAddress, PaymentMethod
    } from '../../components/challenger/index';

    import renderEngine from '../Global/JS/renderEngine.js';

    export default {
        name: 'RenderEngine',
        mixins: [renderEngine],
        components: {
            PetSpecies, PetGender, PetName, PetAge, PetColor, PetBreedType, UserEmailAddress, UserZipCode, UserEmployeer,
            UserAddress, UserFirstName, UserLastName, UserPhone, CustomParagraph, StepHeader, WorkEmailAddress, DotLoader, SendQuote
        },
        data: () => ({
            federalTypeSelection: null,
            federalSelectionRule: value => !!value || 'You must select an option',
        }),        
        computed: {
            federalCivilianList() {
                if(this.dynamicContent && this.dynamicContent?.federalCivilianList) {
                    const federalCivilianList = ['Civilian', ...this.dynamicContent.federalCivilianList];
                    return federalCivilianList.map(option => ({ value: 'c-' + option, label: option }));
                } 
                return [];
            },
            federalUniformedServicesList(){
                if(this.dynamicContent && this.dynamicContent?.federalUniformedServicesList) {
                    const federalUniformedServicesList = ['Uniformed Services', ...this.dynamicContent.federalUniformedServicesList];
                    return federalUniformedServicesList.map(option => ({ value: 'u-' + option, label: option }));
                } 
                return [];
            },
            federalList(){
                return this.federalCivilianList && this.federalUniformedServicesList
                    ? [ ...this.federalCivilianList, ...this.federalUniformedServicesList ]
                    : [];
            }
        },
        created() {
            if(this.showFederalField(this.enrollmentCode)) {
                if(this.civilian) this.federalTypeSelection = 'c-' + this.civilian;
                if(this.uniformedService) this.federalTypeSelection = 'u-' + this.uniformedService;
            } else {
                this.federalTypeSelection = null;
                this.$store.commit("saveCivilian", null);
                this.$store.commit("saveUniformedService", null);
            }
            
            let intervalId = setInterval(() => {
                if (this.defaultSidebarImage || this.dynamicSidebarImage) {
                    this.setImageSource(this.defaultSidebarImage, this.dynamicSidebarImage, "1", "setStep1SideImgSource");
                    clearInterval(intervalId);
                }
            }, 1000);

            setTimeout(() => clearInterval(intervalId), 10000);

        },
        watch: {

        },
        beforeDestroy(){

        },
        mounted() {
            
        },
        methods: {
            failedValidationFocus(){

                let failedValidation = document.querySelector(".v-input.error--text:not(.bypassValidation)");
                    
                if(failedValidation !== null){
                    if(failedValidation.classList.contains('hidden-input')){
                        failedValidation.parentNode.querySelector("div.v-radio").focus();
                    }else{
                        failedValidation.querySelector("input").focus();
                    }
                }
            },
            trackAdobeEventPhone() {
                let ldo = {
                    eventName: 'linkClick',
                    attributes: {
                        application: 'MLPI',
                        form: 'Pet Insurance Quote',
                        linkName: this.getPartnerContactInfo(),
                        stepName: 'Pet Info'
                    }
                };
                digitalData.eventTrack('linkClick', ldo);
            },
            handleFederalRadioChange() {
                this.$store.commit("saveCivilian", null);
                this.$store.commit("saveUniformedService", null);
                
                if(this.federalCivilianList.some(item => item.value === this.federalTypeSelection)) {
                    this.$store.commit("saveCivilian", this.federalTypeSelection.substring(2));
                } else {
                    this.$store.commit("saveUniformedService", this.federalTypeSelection.substring(2));
                }
            }, 
            goToRetrieve() {
                var ldo = {
                    eventName: 'formStart',
                    attributes: {
                        application: 'MLPI',
                        form: 'Fetch Quote',
                        linkName: 'Retrieve a saved quote'
                    }
                }
                digitalData.eventTrack('formStart', ldo);
                this.$router.push({ path: '/retrieve', query: this.$route.query });
            }

        },
        updated(){
            
        }
    }
</script>
<style>
    .v-text-field .v-input__icon--clear {
        opacity: 1  !important;
    }
    ul.messages-list{
        list-style: none;
        padding: 5px 0 0 0;
    }
    .btn-text-compact{
        padding: 0px !important;
    }
</style>