<template>
    <v-text-field
        clearable
        label="Work Email Address"
        aria-label="Enter your work email address, email format is name@example.com."
        :rules="rules"
        type="email"
        v-model="workEmailAddress"
        persistent-hint
        autocomplete="off"
         ref="workEmailAddress"
      ></v-text-field>
</template>

<script>
    export default {
        name: 'WorkEmailAddress',
        data: () => ({
            rules: [
            //value => !!value || 'Required.',
            //value => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,12})+$/.test(value) || 'E-mail must be valid'
            ],
        }),
        computed: {
            workEmailAddress: {
                get() {
                    return this.$store.getters.owner.workEmailAddress;
                },
                set(value) {
                    //updateCurrentQuote was not found in mutations.
                    //this.$store.commit('updateCurrentQuote', { workEmailAddress: value });
                    this.$store.commit('updateOwner', { workEmailAddress: value });
                }
            }
        },
        methods:{
            labelClearIconWhenAvailable(inputRefName) {
                this.$nextTick(()=>{
                    if(this.$refs[inputRefName]){
                        let inputContainer = this.$refs[inputRefName].$el;
                        let input = inputContainer.querySelector("input[type='email']");
                        //Try to label the clear icon
                        let labelText = inputContainer.querySelector("label[for='"+input.getAttribute("id")+"']").innerText;
                        try {
                            let button = inputContainer.querySelector(".v-input__append-inner button");
                            button.setAttribute("aria-label", "Clear values for " + labelText);
                            button.removeAttribute("tabindex");
                        } catch(e) {
                            console.log("An error occured");
                        }
                    }
                });
            },
        },
        mounted() {
            this.labelClearIconWhenAvailable("workEmailAddress");
        },
        updated() {
            this.labelClearIconWhenAvailable("workEmailAddress");
        },
    }
</script>