<template>
    <div class="btn-toggle-container">
        <v-form ref="speciesForm" v-model="formValidSpecies" lazy-validation> 
        <label id="PetSpecies" for="pet-species"> {{ getPetSpeciesLabel() }} <span id="PetSpeciesRequired" class="d-sr-only" aria-hidden="true">(required)</span></label>
        <v-radio-group
            row
                aria-labelledby= "PetSpecies PetSpeciesRequired"
                v-model="petSpecie" 
                :class="formValidSpecies ? 'valid':'invalid'"  
                class="mt-1"
                :aria-errormessage="formValidSpecies ? '' : 'speciesErrors'"
                aria-describedby="speciesMessages"
                :rules="rules"
                hint="Required."
                persistent-hint	

            >
            <v-radio v-for="(petType, typeIndex) in speciesList"
                color="primary"
                :key="petType.id" 
                :data-id="'btn-species-' + petType.id"
                :aria-labelledby="'PetSpecies PetSpeciesRequired btn-species-' + petType.id"
                :value="petType.id"
                :aria-pressed="petSpecie === petType.id ? 'true': 'false'"
                :ref="petType.name"
                aria-describedby="speciesMessages"
                @change="handleRadioChange(typeIndex, petType.name)"
                :label="petType.name === 'OtherExotic' ? 'Exotic' : petType.name"
            >
                <template v-slot:label>
                    <span :id="'species-'+petType.id+'-label'">{{petType.name === 'OtherExotic' ? 'Exotic Pet' : petType.name}}</span>
                </template>
            </v-radio>
        </v-radio-group> 
            
            <v-row >
                <v-col cols="12" class="text-left pt-5 pb-2" >
                    <h5 id="what-is-exotic" class="exotic-banner font-weight-regular text-left">
                        <v-btn aria-labelledby="what-is-exotic" class="pa-0 btn-link-text" color="primary" x-small text v-on:click="showExoticDesc()">What Is An Exotic Pet?</v-btn>
                    </h5>
                </v-col>
            </v-row>
            <v-row class="justify-center" v-if="exoticDesc">
                <v-col cols="12" class="py-0 mb-4">
                    <v-card elevation="0" color="ml_lightgray flex-grow-1 pa-2" style="height:100%;" class="mx-auto" tabindex="0" role="region" aria-labelledby="exoticTooltipText">
                        <v-card-text>
                                    <p class="mb-0 body-2  ml_darkergray--text" id="exoticTooltipText">Exotic pets encompass birds, reptiles, small rodents, amphibians, opossums, hedgehogs, pigs, goats, rabbits, and ferrets.</p>                                
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-scale-transition hide-on-leave>
                <v-card v-if="petSpecie === 6" class="exotic-message my-7 " :ripple="false" flat outlined elevation="0" tabindex="0" role="region" aria-labelledby="exoticGuidanceText">
                    <v-card-title>
                        <v-list-item class="d-flex flex-column flex-md-row px-0">
                            <v-list-item-avatar size="64" tile class="exotic-avatar"></v-list-item-avatar>
                            <v-list-item-content id="exoticGuidanceText" >
                                <p class="mb-0 body-2 line-height-relax">
                                    For a quote or policy on your exotic pet, give us a call at <br /> <a class="font-weight-bold text-decoration-none text-no-wrap" @click="exoticPhoneNum" aria-label="call, 855 421 7227." href="tel:8554217227">(855) 421-7227</a>. Available in: AL, CT, GA, IA, IN, LA, MI, MO, NC, NE, OH, OR, PA, TN, TX, UT, VA, WI, WY.
                                </p>
                                <p  class="mb-0 body-2 line-height-relax" v-if="String(partnerId) === '251072'">
                                    Exotic pet coverage is not currently available in CA.
                                </p>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card-title>
                </v-card>
            </v-scale-transition>
        </v-form>
    </div>
</template>

<script>
    import petSpecies from "../global/js/petSpecies";
    export default {
        mixins: [petSpecies],
        name: 'PetSpecies',
        props: {
            validateSpecies: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            handleRadioChange(typeIndex, petName) {
                this.localValidateSpecies(typeIndex);
                this.checkQuoteStart(petName);
            }
        }, 
        computed: {
            partnerId: function () {
                return this.$store.getters.partnerId;
            },
        }
    }
</script>
<style scoped>
    .line-height-relax{
        line-height: 1.55em;
    }
</style>