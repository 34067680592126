<template>
    <div class="CustomElementIdParagraph">
        <p role="heading" aria-level="2">{{valueText}}</p>
    </div>
</template>

<script>
    export default {
        name: 'CustomParagraph',
        props: ['valueText'],
        data: () => ({

        }),
        methods: {
            update(value){
               
            }
        }
    }
</script>