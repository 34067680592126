<template>
    <v-text-field        
        label="Zip Code"
        :rules="rules"
        hint="Required."
        v-model="zipCode"
        persistent-hint
        class="no-stepper-number-field"
        autocomplete="off"
        v-on:focus="checkQuoteStart"
        aria-label="Enter a 5 digit zipcode number."
        v-mask="'#####'"
        aria-required="true"
        aria-describedby="zip-code-message"
         ref="userZipCode"
         clearable
      >
        <template v-slot:message="{ message,key}">
            <span id="zip-code-message">{{message}}</span>
        </template>
            <!-- <template v-slot:append>
                <v-btn :style="zipCode === '' ? 'opacity:0.25;':'opacity:1;'" aria-label="clear zip Code."  @click="zipCode = ''" id="zipcodeTextClear" small icon> <v-icon >mdi-close</v-icon></v-btn>
            </template> -->

      </v-text-field>
</template>

<script>
    export default {
        name: 'UserZipCode',
        data: () => ({
            rules: [
            value => !!value || 'Please enter your zip code. (Required).',
            value => (value && value.length === 5) || 'Zip Code must be a 5-digit number',
            ],
        }),
        computed: {
            zipCode: {
                get() {
                    return this.$store.getters.owner.zip;
                },
                set(value) {
                    this.$store.commit('updateOwner', { zip: value });
                    this.$store.dispatch('resetSelectedPlans');
                }
            },

            quoteFunnelActivity: {
                get() {
                    return this.$store.getters.quoteFunnelActivity;
                },
                set(value) {
                    this.$store.commit('setQuoteFunnelActivity', value);
                }
            }
        },

         methods: {
            labelClearIconWhenAvailable(inputRefName) {
                this.$nextTick(()=>{
                    if(this.$refs[inputRefName]){
                        let inputContainer = this.$refs[inputRefName].$el;
                        let input = inputContainer.querySelector("input[type='text']")
                        //Try to label the clear icon
                        let labelText = inputContainer.querySelector("label[for='"+input.getAttribute("id")+"']").innerText
                        try {
                            let button = inputContainer.querySelector(".v-input__append-inner button");
                            button.setAttribute("aria-label", "Clear values for " + labelText);
                            button.removeAttribute("tabindex");
                        } catch(e) {}
                    }
                });
            },
            checkQuoteStart() {
                if (!this.quoteFunnelActivity) {
                    this.$store.dispatch("trackAnalyticsEvent", {
                        eventType: "quoteStart",
                        ldo: {
                            eventName: 'quoteStart',
                            attributes: {
                                application: 'MLPI',
                                form: 'Pet Insurance Quote'
                            }
                        }
                    });
                    this.quoteFunnelActivity = true;
                }
            }
         },
         mounted() {
            this.labelClearIconWhenAvailable("userZipCode");
         },
         updated() {           
            this.labelClearIconWhenAvailable("userZipCode");
         }
    }
</script>
