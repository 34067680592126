<template>
    <div class="breed-info-group">
        <v-form  ref="BreedTypeForm" v-model="formValidBreedType" lazy-validation>
        <div class="mb-1 btn-Toggle-container">
            <label for="pet-breed" id="PetBreedType">Your pet's breed is<span class="d-sr-only">(required)</span></label>

            <v-radio-group
                v-if="currentPetSpecie !== 2 "
                aria-labelledby="PetBreedType"
                v-model="isPetMixed"
                id="pet-breed"
                @change="isMixed($event)"
                :class="formValidBreedType ? 'valid':'invalid'"
                class="mt-1"
                :aria-errormessage="formValidBreedType ? '':'breedTypeErrors'"
                role="group"
                :rules="rules"
                aria-describedby="breedTypeMessages"
                row
                hint="Tell us more about your pet's breed. Required."
                persistent-hint
                >
                <v-radio
                    aria-labelledby="PetBreedType btn-breedtype-Pure" 
                    value="false" 
                    :aria-pressed="isPetMixed === 'false' ? 'true': 'false'"
                    ref="Pure"
                    id="Pure"  
                    @blur="localValidateBreedType(0)"
                    aria-describedby="breedTypeMessages breedTypeErrors"
                    v-on:change="checkQuoteStart"
                    label="Pure"
                ></v-radio>
                <v-radio
                    aria-labelledby="PetBreedType btn-breedtype-Mixed" 
                    value="true" 
                    :aria-pressed="isPetMixed  === 'true' ? 'true': 'false'"
                    ref="Mixed" 
                    id="Mixed"
                    @blur="localValidateBreedType(1)"
                    aria-describedby="breedTypeMessages breedTypeErrors"
                    v-on:change="checkQuoteStart"
                    label="Mixed"
                ></v-radio>
            </v-radio-group>
        </div>
        </v-form>

        <div :id="'species-'+currentPetSpecie" :class="valueSelected  === 'undefined' ? '' : 'field-pet-specify'">
            <PetBreed ref="PetBreedDropdown" @change="update" v-if="isPetMixed === 'false'" :selectedValue="valueSelected">
            </PetBreed>
            <PetSize ref="PetSizeDropdown" @change="update" v-else-if="isPetMixed === 'true'" :selectedValue="valueSelected">
            </PetSize>

        </div>
    </div>
</template>

<script>
    import PetBreed from '../PetBreed.vue';
    import PetSize from '../PetSize.vue';

    export default {
        name: 'PetBreedType',
        components: { PetBreed, PetSize },
        props: {
            validateBreedType: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            that: this,
            petBreedDefault: 'Please select a dog or cat above',
            rules: [
            value => !!value || "Please enter your pet's breed. (Required).",
            ],
            listRules: [
                value => value.length === 1 || 'Please select a dog or cat above',
            ],
            formValidBreedType: true,
        }),
        watch: {
            currentPetSpecie: function () {
                var self = this;
                this.update(self.petBreedDefault);
                if(this.$store.getters.currentPet.species === 2){
                    this.$store.commit('updatePet', { mixed: false });
                }
                if(this.$store.getters.currentPet.species !== 2){
                    this.$store.commit('updatePet', { mixed: undefined });
                }
            },
            valueSelected: function (){        
            },
            validateBreedType: function () {
                this.$refs.BreedTypeForm.validate();
            }                    
        },
        computed: {
            isPetMixed: {
                get() {
                    var isMixed = this.$store.getters.currentPet.mixed;                    
                    return isMixed != undefined ? isMixed.toString() : undefined;
                },
                set(value) {
                    var boolVal = value != undefined ? (value == "true") : "";
                    this.$store.commit('updatePet', { mixed: boolVal });
                }
            },            
            currentPet: function () {
                return this.$store.getters.currentPet;
            },
            BreedMessageWrapper: function () {
                if (this.currentPet.name !== '' && this.currentPet.name !== null) {
                    return `Tell us more about ${this.currentPet.name}'s breed. Required.`;
                }
                return "Tell us more about your pet's breed. Required";
            },
            mixedBreedMessageWrapper: function(){
                 if (this.currentPet.name !== '' && this.currentPet.name !== null) {
                    return `Select ${this.currentPet.name}'s most approximate weight. Required. Type weight range or use Arrows to select from list.`;
                }
                return "Select your pet's approximate weight. Required.";
            },
            pureBreedMessageWrapper: function(){
                 if (this.currentPet.name !== '' && this.currentPet.name  !== null) {
                    return `Select ${this.currentPet.name}'s breed. Required.  Type Breed Name or use Arrows to select from list.`;
                }
                return "Select your pet's breed. Required.  Type Breed Name or use Arrows to select from list.";
            },
            currentPetSpecie: function () {
                return this.$store.getters.currentPet.species;
            },
            valueSelected: {
                get() {

                    if (this.isPetMixed == "true") {
                        return parseInt(this.currentPet.weight);
                    } else if (this.isPetMixed == "false") {
                        return this.currentPet.breed;
                    }
                    return undefined;
                },
                set(value) {
                    return value;
                }

            },
            quoteFunnelActivity: {
                get() {
                    return this.$store.getters.quoteFunnelActivity;
                },
                set(value) {
                    this.$store.commit('setQuoteFunnelActivity', value);
                }
            }
        },
        mounted() {
            if(this.$refs.petBreedTypeClickableButton){
                let element = this.$refs.petBreedTypeClickableButton.$el;
                let unknownButtonDiv = element.querySelector("div.v-input__slot");
                unknownButtonDiv.setAttribute("aria-hidden","true");
                unknownButtonDiv.setAttribute("role","none");
            }
        },
        updated() {
        },
        methods: {
            focus(){
                if(this.isPetMixed === "true") {
                    let interval = setInterval(()=>{
                        if(this.$refs.PetSizeDropdown) {
                            this.$nextTick(()=>this.$refs.PetSizeDropdown.focus());
                            clearInterval(interval);
                        }
                    }, 50);
                }
                if(this.isPetMixed === "false") {
                    let interval = setInterval(()=>{
                        if(this.$refs.PetBreedDropdown) {
                            this.$nextTick(()=>this.$refs.PetBreedDropdown.focus());
                            clearInterval(interval);
                        }
                    }, 50);
                }
            },
            moveFocusToMailIfFilled(){
                
            },
            closeOnBlur(){
                this.$refs[petBreedSelectAutocomplete].activateMenu = false;
                this.$refs.petBreedSelectAutocomplete.$el.querySelector("input[type='text']").setAttribute("aria-expanded", this.$refs.petBreedSelectAutocomplete.isMenuActive)
            },
            localValidateBreedType(idx){
                if(idx >= 1){
                    this.$refs.BreedTypeForm.validate();
                }
            },
            moveFocusToBreedType(){
                if(this.isPetMixed === 'true' ){
                    this.goToElementAndFocus('Mixed'); 
                }
                if(this.isPetMixed === 'false'){
                    this.goToElementAndFocus('Pure'); 
                }
            },
            isMixed(event) {
                var self = this;
                this.valueSelected = undefined;
                this.update(self.petBreedDefault);
            },
            update(value) {
                const valueP = { weight: null, breed: null };
                if (!value || value == this.petBreedDefault) {
                    this.valueSelected = undefined;
                    this.$store.commit('updatePet', valueP);
                } else {
                    if (this.isPetMixed === 'true') {
                        valueP['weight'] = value.toString();
                        valueP['breed'] = this.currentPetSpecie == 1 ? 54 : 2;
                    } else {
                        valueP['breed'] = value;
                        valueP['weight'] = null;
                    }
                    this.$store.commit('updatePet', valueP);
                }
            },
            checkQuoteStart() {
                if (!this.quoteFunnelActivity) {
                    this.$store.dispatch("trackAnalyticsEvent", {
                        eventType: "quoteStart",
                        ldo: {
                            eventName: 'quoteStart',
                            attributes: {
                                application: 'MLPI',
                                form: 'Pet Insurance Quote'
                            }
                        }
                    });
                    this.quoteFunnelActivity = true;
                }
            }
        }
    }
</script>