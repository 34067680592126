import Fuse from 'fuse.js';
import Swal from 'sweetalert2';
export default {
    // Careful | in cases where the mixin and the component define 
    // the same property, the component's property will take precedence.
    data() {
        return {
            menuMaxHeight: 200,
            ddlWrapperDivID: "PetEmployer-list-container",
            OpenToTop: false,
            search: null,
            employee: null,
            filteredPartnerList: [],
            partnerList: [],
            rules: [
                value => !!value || 'Required.'
            ],
        }
    },
    computed: {
        enrollmentCode: function () {
            return this.$store.getters.enrollmentCode;
        },
        futureEnrollmentDate: function () {
            return this.$store.getters.futureEnrollmentDate;
        },
        defaultDynamicContent: function () {
            return this.$store.getters.getDefaultDynamicContent;
        }
    },
    watch: {
        search: function (val) {
            if (val != null && val != undefined) {
                this.filterFirstResultFound(val);
            }
        }
    },
    updated() {
        this.labelClearIconWhenAvailable("companySelectAutocomplete");
    },
    mounted(){
        this.initA11yForARIA12Combo("companySelectAutocomplete");
        this.labelClearIconWhenAvailable("companySelectAutocomplete");
        this.labelExpandIconOnMount("companySelectAutocomplete");
    },
    methods: {
        handleInput(value) { },
        initA11yForARIA12Combo(comboRefName) {
            this.$nextTick(()=>{
                if(this.$refs[comboRefName]){
                    let comboContainer = this.$refs[comboRefName].$el;
                    let input = comboContainer.querySelector("input[type='text']")
                    //Initially try to adjust roles of wrapper div and input inside it
                    try{
                        let comboRoleDiv = comboContainer.querySelector("[role='combobox']");
                        let listId = comboRoleDiv.getAttribute("aria-owns");
                        comboRoleDiv.removeAttribute("role");
                        comboRoleDiv.removeAttribute("aria-owns");
                        comboRoleDiv.removeAttribute("aria-haspopup");
                        comboRoleDiv.removeAttribute("aria-expanded");
                        if(listId !== null) input.setAttribute("aria-controls", listId );
                    } catch(e) {}
                }
            });
        },
        labelExpandIconOnMount(comboRefName) {
            this.$nextTick(()=>{
                if(this.$refs[comboRefName]){
                    let comboContainer = this.$refs[comboRefName].$el;
                    let input = comboContainer.querySelector("input[type='text']")
                    //Try to label the expand icon
                    let labelText = comboContainer.querySelector("label[for='"+input.getAttribute("id")+"']").innerText
                    try {
                        let button = comboContainer.querySelector(".v-input__append-inner div:has(>i)");
                        button.setAttribute("aria-label", "Expand list for " + labelText);
                        button.setAttribute("role", "button");
                        button.setAttribute("aria-expanded", "false");
                        button.addEventListener("click", this.handleExpandIconClick);
                    } catch(e) {}
                }
            });
        },
        handleKeyDown(e) {
            let input = e.target;
            let expandIcon = input.parentElement.querySelector(".v-input__append-inner div:has(>i)");

            if(input.getAttribute("aria-expanded") === "true" && (e.key === "Escape" || e.key === "Tab" || (e.key === "Enter" && input.getAttribute("aria-activedescendant") !== ""))){
                this.setAsCollapsed(input, expandIcon);
            }

            if(input.getAttribute("aria-expanded") === "true" && e.key === "Backspace" && /iPad|iPhone/.test(navigator.userAgent) && !window.MSStream) {
                this.removeTabindexFromListBox(input);
            }
        },
        setAsExpanded(input, expandIcon) {
            // ipad and ios fix for inverse state;
            var iOS= /iPad|iPhone/.test(navigator.userAgent) && !window.MSStream;

            if(iOS){
                this.removeTabindexFromListBox(input);
            }

            this.OpenToTop = this.openDirectionCheckup(this.ddlWrapperDivID, this.menuMaxHeight);
            let state = "true";
            input.setAttribute("aria-expanded", state);
            expandIcon?.setAttribute("aria-expanded", state);
            this.assignClickToSelectedOption(input, expandIcon);
        },
        setAsCollapsed(input, expandIcon) {
            let state = "false";

            input.setAttribute("aria-expanded", state);
            expandIcon?.setAttribute("aria-expanded", state);
        },
        handleBlur(e) {
            let input = e.target;
            let expandIcon = input.parentElement.querySelector(".v-input__append-inner div:has(>i)");

            this.setAsCollapsed(input, expandIcon);
            this.toolitpShow = false;
        },
        removeTabindexFromListBox(input){
            this.$nextTick(() => {
                setTimeout(() => {
                    let listBoxContainer =  document.getElementById(input.getAttribute("aria-controls"));
                    listBoxContainer.removeAttribute("tabindex");

                    let listBoxOptions = listBoxContainer.querySelectorAll("div[role=option]");
                    listBoxOptions.forEach(optionElement=> {
                        optionElement.removeAttribute("tabindex")
                    });
                },100);
            })
        },
        assignClickToSelectedOption(input, expandIcon) {
            this.$nextTick(() => {
                setTimeout(() => {
                    let selectedListContainer = document.getElementById(input.getAttribute("aria-controls"));
                
                    if(selectedListContainer) {
                        let selectedListItem = selectedListContainer.querySelector("div[role=option][aria-selected=true]");

                        let itemClickHandler = () => {
                            this.setAsCollapsed(input, expandIcon);
                            this.focus();//focus lost fix for iphone and ipad
                            selectedListItem.removeEventListener("click", itemClickHandler);
                        };
                        try{
                            selectedListItem.addEventListener("click", itemClickHandler);
                        } catch {};
                    }
                    
                }, 100);
            });
        },
        handleExpandOnClick(e) {
            let input = e.target;
            let expandIcon = input.parentElement.querySelector(".v-input__append-inner div:has(>i)");
            input.setAttribute("aria-expanded","true");
            expandIcon.setAttribute("aria-expanded","true");
        },
        handleExpandIconClick(e) {
            let expandIcon = e.currentTarget;
            let input = expandIcon.parentElement.parentElement.querySelector("input[type=text]");
            if (expandIcon.getAttribute("aria-expanded") === "false") this.setAsExpanded(input, expandIcon);
            else this.setAsCollapsed(input, expandIcon);
        },
        labelClearIconWhenAvailable(comboRefName) {
            this.$nextTick(()=>{
                if(this.$refs[comboRefName]){
                    let comboContainer = this.$refs[comboRefName].$el;
                    let input = comboContainer.querySelector("input[type='text']")
                    //Try to label the clear icon
                    let labelText = comboContainer.querySelector("label[for='"+input.getAttribute("id")+"']").innerText
                    try {
                        let button = comboContainer.querySelector(".v-input__append-inner button");
                        button.setAttribute("aria-label", "Clear values for " + labelText);
                        button.removeAttribute("tabindex");
                    } catch(e) {}
                }
            });
        },
        declareDropdownCollapseState() {
            this.$nextTick(() => {
                let input = this.$refs.companySelectAutocomplete.$el.querySelector("input[type='text']");
                let expandIcon = this.$refs.companySelectAutocomplete.$el.querySelector(".v-input__append-inner div:has(>i)");
                this.setAsCollapsed(input, expandIcon);
            });
        },
        declareDropdownExpandState(){
            this.$nextTick(() => {
                let input = this.$refs.companySelectAutocomplete.$el.querySelector("input[type='text']");
                let expandIcon = this.$refs.companySelectAutocomplete.$el.querySelector(".v-input__append-inner div:has(>i)");

                this.setAsExpanded(input, expandIcon);
            });
        },
        focus(){
             this.$refs['companySelectAutocomplete'].$el.querySelector("input[type=text]").focus();
        },
        handleSearchInput(searchText,filteredList){
            let i;
            for(i=0;i<filteredList.length;i++){
                if(filteredList[i].partnerName.toUpperCase().includes(searchText.toUpperCase())){
                    break;
                }
            }
            if(i<filteredList.length && (this.valueIsChosen === undefined || this.valueIsChosen=== false )) {
                    this.declareDropdownExpandState();     
            } else {
                this.valueIsChosen=false;
                this.declareDropdownCollapseState();
            }
        },
        filterFirstResultFound(searchText) {
            var fuseSearch = new Fuse(this.partnerList,{
                keys: [
                    "partnerName"
                ]
            });
            var results = fuseSearch.search(searchText, {limit: 5});
            
            var filteredList = [];
            results.forEach(result => {
                filteredList.push(result.item);  
            });
            
            this.handleSearchInput(searchText,filteredList);
            this.filteredPartnerList = filteredList;
        },
        async getPartnerInfo(partnerId) {
            var self = this;

            this.$store.commit("savePartnerId", partnerId);

            this.$store.commit("setPartnerLogoSource","");
            var response = await self.$store.dispatch("getPartnerInfoByPartnerId");

            if (this.futureEnrollmentDate) {
                Swal.fire({
                    title: 'Future Enrollment Date',
                    text: "Your group's enrollment period is not yet open. Please contact us at 1-800-GETMET8 to review coverage options for your pet.",
                    icon: 'info',
                    confirmButtonText: 'Okay'
                }).then(() => {
                    sessionStorage.clear();
                    this.$store.reset();
                    window.location.replace("https://www.metlifepetinsurance.com/pet-insurance/");
                });
            }

            this.getDynamicContent(response ? response.cdfCustomerNumber : null)

            if(response && response.enrollmentCode) self.$store.commit("saveEnrollmentCode", response.enrollmentCode);

            return;
        },
        getDynamicContent(partnerId) {
            if(partnerId) {
                this.$store.dispatch("getAdminConfig", {
                    payload: { partnerId: partnerId, enrollmentCode: undefined, urlString: undefined }
                });
            } else {
                this.$store.commit('clearDynamicContent');
                sessionStorage.getItem("partnerLogoSource");
                sessionStorage.removeItem('step1SideImgSource');
                sessionStorage.removeItem('step2SideImgSource');
                sessionStorage.removeItem('step3SideImgSource');
                sessionStorage.removeItem('step4SideImgSource');
            }
            
        }
    }
}