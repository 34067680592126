<template>
    <div>
        <v-tooltip 
            :top="tooltipMobile"
            :right="true"  
            :disabled="true"
            color="#6C6C6C"
        >
        <!-- :right="!tooltipMobile" -->
        <!-- :disabled="!employeeToken" -->
    
            <template v-slot:activator="{ on }">
                <v-text-field 
                    :label="emailAddressLabel"
                    :rules="rules"
                    hint="Required."
                    type="email"
                    class="mb-3"

                    @change.native="handleInputChange"
                    v-model="userEmailAddress"
                    id="emailAddressField"
                    ref="emailAddressField"
                    persistent-hint
                    autocomplete="email "
                    v-on="on"
                    v-on:focus="checkQuoteStart"
                    aria-describedby="user-email-message"
                    v-on:blur="sendEmailToAnalytics"
                    clearable
                >
                <!-- :aria-describedby="employeeToken ? 'user-eml-desc-tooltip' : 'user-email-message'" -->
                <!-- error-message slot -->
                <template v-slot:message="{ message,key}">
                    <span id="user-email-message">{{message}}</span>
                    <!-- <span :id="employeeToken?'':'user-email-message'">{{message}}</span> -->
                 </template>
            </v-text-field>
            </template>
            <!-- <span id="user-eml-desc-tooltip">
                We ask for personal email so <br />
                we can ensure continuity of <br />
                communications if work email <br />
                is not available
            </span> -->
        </v-tooltip>

        <!-- <work-email-address class="mb-3 pt-3 mt-0" v-if="employeeToken" /> -->
    </div>
</template>

<script>
    export default {
        name: 'UserEmailAddress',
        components: {
            WorkEmailAddress: () => import("./WorkEmailAddress.vue")
        },
        data: () => ({
            rules: [
                value => !!value || 'Please enter your email. (Required).',
                value => !value || /^(?:[a-zA-Z0-9]|[._-][a-zA-Z0-9]+)([._-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,12}$/.test(value) || 'E-mail must be valid.'
            ],
            sentToAnalytics: false,
            emailSentToAnalytics: ""
        }),
        computed: {
            employeeToken: function () {
                return this.$store.getters.employeeToken;
            },
            emailAddressLabel: function () {
                return "Email Address"; // this.employeeToken ? "Personal Email Address" : "Email Address"
            },
            emailAddressAriaLabel: function () {
                return "Enter your email address, email format is name@example.com. Required."
                // this.employeeToken 
                //     ? "Enter your personal email address, email format is name@example.com. Required." 
                //     : "Enter your email address, email format is name@example.com. Required."
            },
            userEmailAddress: {
                get() {
                    return this.$store.getters.owner.email;
                },
                set(value) {
                    this.$store.commit('updateOwner', { email: value });
                }
            },

            quoteFunnelActivity: {
                get() {
                    return this.$store.getters.quoteFunnelActivity;
                },
                set(value) {
                    this.$store.commit('setQuoteFunnelActivity', value);
                }
            },
            tooltipMobile: function () {
                return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm' ? true : false
            }
        },
        mounted() {
             this.labelClearIconWhenAvailable("emailAddressField");
        },
        updated() {
             this.labelClearIconWhenAvailable("emailAddressField");
        },
        methods: {
            handleInputChange(e) {
                this.labelClearIconWhenInputChanged(e.target);
            },
            labelClearIconWhenInputChanged(input) {
                if(input.value !== "") {
                    let label = input.previousElementSibling;

                    let iconButton = input.parentElement.nextElementSibling.querySelector("button");
                    
                    let srSpan = document.createElement("span");
                    srSpan.setAttribute("class", "d-sr-only");
                    srSpan.innerText = "Clear values for " + label.innerText;
                    iconButton.appendChild(srSpan);
                    iconButton.removeAttribute("aria-label");
                    //iconButton.setAttribute("aria-label", "Clear values for " + label.innerText);
                    iconButton.removeAttribute("tabindex");
                }
            },
            labelClearIconWhenAvailable(inputRefName) {
                this.$nextTick(()=>{
                    if(this.$refs[inputRefName]){
                        let inputContainer = this.$refs[inputRefName].$el;
                        let input = inputContainer.querySelector("input[type='email']");
                        
                        let labelText = inputContainer.querySelector("label[for='"+input.getAttribute("id")+"']").innerText;
                        try {
                            inputContainer.querySelector(".v-input__append-inner .v-input__icon.v-input__icon--clear>button").setAttribute("aria-label", "Clear values for " + labelText);
                        } catch(e) {}
                    }
                });
            },
            moveFocusToPetBreedorBreed(){
                
            },
            sendEmailToAnalytics(){
                if(!this.sendEmailToAnalytics || this.emailSentToAnalytics !== this.userEmailAddress){
                    this.$store.dispatch("trackAnalyticsEvent",{
                        eventType: "emailCheck",
                        ldo: {
                            eventName: 'emailCheck',
                            attributes: { 
                                application: 'MLPI',
                                quote:'Pet Insurance Quote',
                                emailValue: this.userEmailAddress
                            }
                        }
                    });
                    this.emailSentToAnalytics = this.userEmailAddress;
                    this.sentToAnalytics = true;
                }
            },

            checkQuoteStart() {
                if (!this.quoteFunnelActivity) {
                    this.$store.dispatch("trackAnalyticsEvent", {
                        eventType: "quoteStart",
                        ldo: {
                            eventName: 'quoteStart',
                            attributes: {
                                application: 'MLPI',
                                form: 'Pet Insurance Quote'
                            }
                        }
                    });
                    this.quoteFunnelActivity = true;
                }
            }
        }
    }
</script>