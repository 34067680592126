<template>
<div style="position: relative;" :id="ddlWrapperDivID">
    <div class="d-flex flex-row align-end flex-grow-1">
        <v-autocomplete 
            @focus="toolitpShow = true"
            @input="handleInput"
            hide-details
            hint="Type a name and use Arrows to select from list..."
            v-model="employee"
            :items="filteredPartnerList"
            :menu-props="{maxHeight: '200', offsetY: true, offsetOverflow: true, top: OpenToTop}"
            item-text="partnerName"
            item-value="partnerId"
            label="Enter your Employer"
            attach="#PetEmployer-list-container "
            :search-input.sync="search"
            @change="employeerSelected"
            @keydown="handleKeyDown"
            @blur="handleBlur"
            clearable
            hide-selected
            id="companySelect"
            aria-expanded="false"
            aria-autocomplete="list"
            ref="companySelectAutocomplete"
            role="combobox"
            aria-describedby="petEmloyerSelectDescription"
            hide-no-data 
            :loading="loading"
            :disabled="loading"
            class="no-drop"
        >    
            <template v-slot:message="{ message,key }">
                <span id="petEmloyerSelectDescription">{{message}}</span>
            </template>  
        </v-autocomplete>
        <v-menu v-model="toolitpShow" bottom :offset-y="offsetTipY">
                <template v-slot:activator="{on, attrs }">
                    <v-icon class="ml-2 icon-tooltip" color="primary" v-bind="attrs" v-on="on"  small>mdi-help</v-icon>
                </template>
                <v-card elevation="2" outlined >
                    <v-card-text class="py-3">
                        <span>You can save as much as <b>10%</b> through your employee benefits program. <a aria-label="Go to Footnote 3 on Discounts." id="anchorFootnote3" ref="anchorFootnote3" @click="goToElementAndFocus('footnote-3', 'footnote3')"><sup>[3]</sup></a></span>
                    </v-card-text>
                </v-card>
        </v-menu>
<!--                                 
        <v-tooltip color="white" v-model="toolitpShow" bottom max-width="300">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon class="ml-2 icon-tooltip" color="primary" v-bind="attrs" v-on="on"  small>mdi-help</v-icon>
                </template>
                <span>You can save as much as <b>10%</b> through your employee benefits program. <a aria-label="Go to Footnote 3 on Discounts." id="anchorFootnote3"  ref="anchorFootnote3" @click="goToElementAndFocus('footnote-3', 'footnote3')"><sup>[3]</sup></a></span>
        </v-tooltip> -->
    </div>
    <!-- <div v-if="employee !== null" class="v-message theme--light error-text pt-2" role="alert" >
        <div class="v-messages__wrapper" >
            <div class="v-messages__message">
                Entering the employer name will add, personal email address, & work email address above, please provide your inputs
            </div>
        </div>
    </div> -->
</div >
</template>
<script>
    import { Fragment } from 'vue-fragment';
    import Swal from 'sweetalert2';
    import partner from './global/js/partner.js';
    export default {
        name: 'UserEmployeer',
        mixins: [partner],
        components: {Fragment},
        data: () => ({
            loading: false,
            toolitpShow: false,
            offsetTipY: true,
        }),
        computed: {
            partner: function () {
                return this.$store.getters.partner;
            }
        },
        created() {
            this.loading = true;
            this.$store.dispatch("getListOfPartners").then(res => {
                var partnerList = [];
                res.forEach((partner) => {
                    if (partner.partnerAliases !== null) {
                        partner.partnerAliases.forEach((alias) => {
                            var aliasPartner = {
                                "partnerId": partner.partnerId,
                                "partnerName": alias
                            };
                            partnerList.push(aliasPartner);
                        });
                    }
                    partnerList.push(partner);
                });

                // SORT LIST BY NAME TO SHOW IN PROPER ORDER
                partnerList.sort(function (a, b) {
                    var textA = a.partnerName.toUpperCase();
                    var textB = b.partnerName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });

                this.partnerList = partnerList;

                // Remove Required Field if enrollment code is from 
                // Ads Lead
                if (this.enrollmentCode === "99-99-03-1111") {
                    this.rules = [];
                }

                this.$nextTick(()=>{
                    if(this.partner?.id) {
                        this.search = this.partner.name;
                        this.employee = this.partner.id;

                        this.getPartnerInfo(this.partner.id);
                    } 
                });
                this.loading = false;
            });
        },
        methods: {
            employeerSelected(value) {
                this.valueIsChosen = true;
                this.getPartnerInfo(value);
                this.declareDropdownCollapseState();
                this.focus();//focus lost fix for iphone and ipad                
            }
        }
    }
</script>

<style>
.no-drop .v-input__icon.v-input__icon--append{
    display: none;
}

</style>