<template>
      <v-text-field
        clearable
        label="Phone Number"
        :rules="rules"
        hint="*Required."
        v-model="userPhone"
        persistent-hint
        autocomplete="off"
        v-mask="'(###) ###-####'"
      ></v-text-field>
</template>

<script>
    export default {
        name: 'UserPhoneNumber',
        data: () => ({
            rules: [
            value => !!value || 'Required.',
            value => !value || /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value) || 'Phone number must be valid'
            ],
        }),
        computed: {
            userPhone: {
                get() {
                    return this.$store.getters.owner.pn;
                },
                set(value) {
                    this.$store.commit('updateOwner', { pn: value });
                }
            }
        }
    }
</script>