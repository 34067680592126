// eslint-disable-next-line no-unused-vars
export const ElementGuid = {
    ElementIdAnimal : 'a5c6986f-a92e-4c8d-a246-5c6b59c27266',
    ElementIdGender : '60b7d16a-3246-4aad-80e1-a5ce7eace36b',
    ElementIdAge : '358ceee1-c903-43ce-a41a-ef50f19d4504',
    ElementIdColor : 'bee3120a-276e-4344-b035-b2fdc8d62bdb',
    ElementIdBreed : 'ed6de5c9-b57f-4d39-a0b8-772e27f6dd09',
    ElementIdSize : 'adacde98-acd8-4f9a-9948-a4c26b148839',
    ElementIdEmail : 'f220f392-aa25-4b24-959c-62afbc947efa',
    ElementIdWorkEmail : 'f220f392-aa25-4b24-959c-62afbc947efb',
    ElementIdZipCode : 'f220f392-aa25-4b24-959c-62afbc947efc',
    ElementIdFirstName : 'a3aff81d-30a1-4c93-8ac8-379c9df431c8',
    ElementIdLastName : '0410adb4-a8d8-461b-a365-f0a79cf8f011',
    ElementIdAddress : '0efc4725-32c2-4a7a-bf47-3fb196d8d34a',
    ElementIdCityStateZip : 'd8c22235-a220-4989-87a0-bc10ded6c412',
    ElementIdPhone : 'daf1392a-fc5a-4209-b78b-9759172dff52',
    ElementIdPetName : '83d80cdf-d581-47c4-943b-9f63dfa80f74',
    ElementIdDiscounts : 'ce710a7f-e03c-4590-9e40-6061c06df052',
    CustomElementIdParagraph : 'a1b91aa8-9eb4-4202-a004-778e165ca3a5',
  };
