<template>
      <v-text-field
        clearable
        label="Last Name"
        :rules="rules"
        hint="*Required."        
        v-model="userLastName"
        persistent-hint
        autocomplete="off"
      ></v-text-field>
</template>

<script>
    export default {
        name: 'UserLastName',
        data: () => ({
            rules: [
            value => !!value || 'Required.',
            value => (value && value.trim().length >= 1) || 'Min 1 characters',
            ],
        }),
        computed: {
            userLastName: {
                get() {
                    return this.$store.getters.owner.lastName;
                },
                set(value) {
                    this.$store.commit('updateOwner', { lastName: value });
                }
            }
        }
    }
</script>