<template>
    <div class="btn-toggle-container">
        <v-form ref="GenderForm" v-model="formValidGender" lazy-validation> 
        <label for="pet-gender" id="PetGender" >Your pet's gender is <span id="PetGenderRequired" aria-hidden="true" class="d-sr-only">(required)</span></label>
        <v-radio-group
                row
                aria-labelledby= "PetGender PetGenderRequired"
                v-model="petGender" 
                :class="formValidGender ? 'valid':'invalid'"
                class="mt-1" 
                :aria-errormessage="formValidGender ? '':'genderErrors'"
                aria-describedby="pet-gender-message"
                :rules="rules"
                hint="Please tell us about your pet's gender. Required."
                persistent-hint
            >
            <v-radio v-for="(gender, genderIndex) in genderList"
                :key="gender.id" 
                :aria-labelledby= "'PetGender PetGenderRequired btn-gender-' + gender.id"
                :value="gender.id"
                :label="gender.name"
                :aria-pressed="petGender === gender.id ? 'true':'false'" 
                aria-describedby="speciesMessages"
                @blur="localValidateGender(genderIndex)"
                v-on:change="checkQuoteStart"
            >
            </v-radio>
        </v-radio-group> 
       
        </v-form>
    </div>
</template>

<script>
    export default {
        name: 'PetGender',
        props: {
            validateGender: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                genderList: [],
                rules: [
                    value => !!value || "Please enter your pet's gender. (Required).",
                ],
                formValidGender: true
            }
        },
        created() {
            this.getGenders();
        },
        computed: {
            petGender: {
                get() {
                    return this.$store.getters.currentPet.gender;
                },
                set(value) {
                    this.$store.commit('updatePet', { gender: value });
                }
            },
            quoteFunnelActivity: {
                get(){
                    return this.$store.getters.quoteFunnelActivity;
                },
                set(value){
                    this.$store.commit('setQuoteFunnelActivity',value);
                }
            }
        },
        methods: {
            getGenders() {
                this.genderList = this.$store.getters.petGenderList;

                if (this.genderList.length == 0) {
                    this.$store.dispatch("getGenders")
                        .then(res => {
                            this.genderList = res;
                            this.$store.commit("setPetGenderList", res);
                        });
                }
            },
            localValidateGender(idx){
                if(idx >= 1){
                    this.$refs.GenderForm.validate();
                }
            },
            checkQuoteStart() {
                if(!this.quoteFunnelActivity){
                    this.$store.dispatch("trackAnalyticsEvent",{
                        eventType: "quoteStart",
                        ldo: {
                            eventName: 'quoteStart',
                            attributes: { 
                                application: 'MLPI',
                                form:'Pet Insurance Quote'
                            }
                        }
                    });
                    this.quoteFunnelActivity = true;
                }
            }
        },
        watch: {
            validateGender: function (val){
                if(val){
                    this.$refs.GenderForm.validate();
                }
            }
        },
        mounted() {
            if(this.$refs.petGenderTypeClickableButton) {
                let element = this.$refs.petGenderTypeClickableButton.$el;
                let unknownButtonDiv = element.querySelector("div.v-input__slot");
                unknownButtonDiv.setAttribute("aria-hidden","true");
                unknownButtonDiv.setAttribute("role","none"); 
            }
        }
    }
</script>